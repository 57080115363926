// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cLFeeoiOBdaPjhLSO2GQ{padding:.7rem .625rem;border-radius:.625rem;background:#2b2a2a;display:flex;align-items:center;justify-content:space-between;margin-bottom:20px;transition:all .3s ease 0s}.cLFeeoiOBdaPjhLSO2GQ:hover{background:#383838}.zhEHK3X6FbNcCFRVib_Y{display:flex;align-items:center;gap:.9375rem}.bODzXncsKv_8ugosAltQ{font-size:1.125rem;word-wrap:break-word}`, "",{"version":3,"sources":["webpack://./src/components/link/link.module.scss"],"names":[],"mappings":"AAAA,sBACE,qBAAA,CACA,qBAAA,CACA,kBAAA,CACA,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,kBAAA,CACA,0BAAA,CACA,4BACE,kBAAA,CAEF,sBACI,YAAA,CACA,kBAAA,CACA,YAAA,CAEJ,sBACI,kBAAA,CACA,oBAAA","sourcesContent":[".link {\n  padding: 0.7rem 0.625rem;\n  border-radius: 0.625rem;\n  background: #2b2a2a;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 20px;\n  transition: all 0.3s ease 0s;\n  &:hover {\n    background: #383838;\n  }\n  &Body {\n      display: flex;\n      align-items: center;\n      gap: 0.9375rem;\n  }\n  &Title {\n      font-size: 1.125rem;\n      word-wrap: break-word;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": `cLFeeoiOBdaPjhLSO2GQ`,
	"linkBody": `zhEHK3X6FbNcCFRVib_Y`,
	"linkTitle": `bODzXncsKv_8ugosAltQ`
};
export default ___CSS_LOADER_EXPORT___;
