import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
import { Fragment, useEffect, useState } from 'react';
import { v1 } from 'uuid';
import { Div, IconSelector, Title } from '@delab-team/de-ui';
import { Card } from '../../../../components/card';
import { CardSkeleton } from '../../../../components/card-skeleton';
import { DataApi } from '../../../../logic/data-api';
import s from './categories.module.scss';
const textTgStyles = { color: 'var(--tg-theme-text-color)' };
const wrapperTgStyles = { border: '1px solid var(--tg-theme-secondary-bg-color)' };
const dividerTgStyles = { borderBottom: '1px solid var(--tg-theme-secondary-bg-color)' };
export const Categories = ({ isTg }) => {
    const [data, setData] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPageDeLab, setCurrentPageDeLab] = useState(1);
    const [currentPageMap, setCurrentPageMap] = useState({});
    const itemsPerPage = 3;
    const API = new DataApi();
    useEffect(() => {
        try {
            setIsLoading(true);
            API.getItems()
                .then((res) => {
                setData(res);
            })
                .catch((error) => {
                console.error('Error when including data:', error);
            })
                .finally(() => setIsLoading(false));
        }
        catch (error) {
            console.error('API.getItems():', error);
        }
    }, []);
    const groupedItems = {};
    data?.forEach((item) => {
        if (item.categories) {
            item.categories.forEach((category) => {
                if (!groupedItems[category]) {
                    groupedItems[category] = [];
                }
                groupedItems[category].push(item);
            });
        }
    });
    const totalPagesDeLab = Math.ceil((groupedItems.DeLab || []).length / itemsPerPage) || 1;
    const startIndexDeLab = (currentPageDeLab - 1) * itemsPerPage;
    const endIndexDeLab = startIndexDeLab + itemsPerPage;
    return (_jsxs(Div, { className: s.inner, children: [groupedItems.DeLab && (_jsxs(Div, { className: s.allApps, tgStyles: wrapperTgStyles, children: [_jsxs("div", { className: s.allAppsTop, children: [_jsx(Title, { variant: "h3", className: s.allAppsTitle, tgStyles: textTgStyles, children: "From DeLab" }), _jsxs("div", { className: s.pagination, children: [_jsx("button", { onClick: () => setCurrentPageDeLab(prevPage => Math.max(prevPage - 1, 1)), disabled: currentPageDeLab === 1, children: _jsx(IconSelector, { id: "chevron-left", size: "20px", color: currentPageDeLab === 1 ? '#666' : '#3E88F7' }) }), _jsx("button", { onClick: () => setCurrentPageDeLab(prevPage => Math.min(prevPage + 1, totalPagesDeLab)), disabled: currentPageDeLab === totalPagesDeLab, children: _jsx(IconSelector, { id: "chevron-right", size: "20px", color: currentPageDeLab === totalPagesDeLab ? '#666' : '#3E88F7' }) })] })] }), _jsx("div", { className: s.allAppsItems, children: isLoading
                            ? Array(4).fill(null).map(_ => _jsx(CardSkeleton, { isTg: isTg }, v1()))
                            : (groupedItems.DeLab || []).slice(startIndexDeLab, endIndexDeLab).map((el, index) => (_jsxs(Fragment, { children: [_jsx(Card, { ...el }, v1()), index !== endIndexDeLab - 1 && _jsx(Div, { className: s.divider, tgStyles: dividerTgStyles })] }, v1()))) })] }, "DeLab")), Object.keys(groupedItems)
                .filter(category => category !== 'DeLab')
                .map((category) => {
                const totalPagesMap = Math.ceil((groupedItems[category] || []).length / itemsPerPage) || 1;
                const startIndexMap = ((currentPageMap[category] || 1) - 1) * itemsPerPage;
                const endIndexMap = startIndexMap + itemsPerPage;
                const isAtBeginning = currentPageMap[category] === 1;
                return (_jsxs(Div, { className: s.allApps, tgStyles: wrapperTgStyles, children: [_jsxs("div", { className: s.allAppsTop, children: [_jsx(Title, { variant: "h3", className: s.allAppsTitle, tgStyles: textTgStyles, children: category }), _jsxs("div", { className: s.pagination, children: [_jsx("button", { onClick: () => setCurrentPageMap((prev) => {
                                                const updatedPage = Math.max((prev[category] ?? 1) - 1, 1);
                                                return {
                                                    ...prev,
                                                    [category]: updatedPage
                                                };
                                            }), disabled: isAtBeginning, children: _jsx(IconSelector, { id: "chevron-left", size: "20px", color: isAtBeginning || !currentPageMap[category] ? '#666' : '#3E88F7' }) }), _jsx("button", { onClick: () => setCurrentPageMap((prev) => {
                                                const updatedPage = Math.min((prev[category] ?? 1) + 1, totalPagesMap);
                                                return {
                                                    ...prev,
                                                    [category]: updatedPage
                                                };
                                            }), disabled: currentPageMap[category] === totalPagesMap || totalPagesMap <= itemsPerPage, children: _jsx(IconSelector, { id: "chevron-right", size: "20px", color: currentPageMap[category] === totalPagesMap || totalPagesMap <= itemsPerPage ? '#666' : '#3E88F7' }) })] })] }), _jsx("div", { className: s.allAppsItems, children: isLoading
                                ? Array(4)
                                    .fill(null)
                                    .map((_, index) => _jsx(CardSkeleton, { isTg: isTg }, index))
                                : groupedItems[category].slice(startIndexMap, endIndexMap).map((el, index) => (_jsxs(Fragment, { children: [_jsx(Card, { ...el }, v1()), index !== endIndexMap - 1 && _jsx(Div, { className: s.divider, tgStyles: dividerTgStyles })] }, v1()))) })] }, category));
            })] }));
};
