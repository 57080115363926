// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.h7wjhKTCBT4m04bjvWfC{max-width:100%}`, "",{"version":3,"sources":["webpack://./src/components/card-skeleton/card-skeleton.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA","sourcesContent":[".skeletonInner {\n  max-width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"skeletonInner": `h7wjhKTCBT4m04bjvWfC`
};
export default ___CSS_LOADER_EXPORT___;
