import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AppInner } from '@delab-team/de-ui';
import { Layout } from './layout';
import { Home } from './pages/home';
import { ROUTES } from './utils/router';
export const App = () => {
    const [firstRender, setFirstRender] = useState(false);
    const [isTg, setIsTg] = useState(false);
    useEffect(() => {
        const isTgCheck = window.Telegram.WebApp.initData !== '';
        const TgObj = window.Telegram.WebApp;
        const bodyStyle = document.body.style;
        setIsTg(isTgCheck);
        if (isTgCheck) {
            TgObj.ready();
            TgObj.enableClosingConfirmation();
            TgObj.expand();
            bodyStyle.backgroundColor = 'var(--tg-theme-bg-color)';
            bodyStyle.setProperty('background-color', 'var(--tg-theme-bg-color)', 'important');
        }
        setFirstRender(true);
    }, [firstRender]);
    return (_jsx(AppInner, { isTg: isTg, children: _jsx(Layout, { children: _jsxs(Routes, { children: [_jsx(Route, { path: ROUTES.HOME, element: _jsx(Home, { isTg: isTg }) }), _jsx(Route, { path: "*", element: _jsx(Navigate, { to: "/", replace: true }) })] }) }) }));
};
