// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MRsF5lq7ZWWrL1j5UlB3{margin-bottom:2rem}.Do0Hzpmqaiu_7V3iDe0O{text-align:center;font-size:1.125rem}`, "",{"version":3,"sources":["webpack://./src/pages/home/sections/settings/settings.module.scss"],"names":[],"mappings":"AACE,sBACI,kBAAA,CAEJ,sBACI,iBAAA,CACA,kBAAA","sourcesContent":[".settings {\n  &Actions {\n      margin-bottom: 2rem;\n  }\n  &Version {\n      text-align: center;\n      font-size: 1.125rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"settingsActions": `MRsF5lq7ZWWrL1j5UlB3`,
	"settingsVersion": `Do0Hzpmqaiu_7V3iDe0O`
};
export default ___CSS_LOADER_EXPORT___;
