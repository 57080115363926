import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
import { Fragment, useEffect, useState } from 'react';
import { Div, IconSelector, Title } from '@delab-team/de-ui';
import { v1 } from 'uuid';
import { Card } from '../../../../components/card';
import { DataApi } from '../../../../logic/data-api';
import s from './all-apps.module.scss';
import { CardSkeleton } from '../../../../components/card-skeleton';
const textTgStyles = { color: 'var(--tg-theme-text-color)' };
const wrapperTgStyles = { border: '1px solid var(--tg-theme-secondary-bg-color)' };
const dividerTgStyles = { borderBottom: '1px solid var(--tg-theme-secondary-bg-color)' };
export const AllApps = ({ isTg }) => {
    const [data, setData] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const API = new DataApi();
    useEffect(() => {
        try {
            setIsLoading(true);
            API.getItems()
                .then((res) => {
                setData(res);
            })
                .catch((error) => {
                console.error('Error when including data:', error);
            }).finally(() => setIsLoading(false));
        }
        catch (error) {
            console.error('API.getItems():', error);
        }
    }, []);
    const ITEMS_PER_PAGE = 5;
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil((data?.length || 0) / ITEMS_PER_PAGE);
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = Math.min(startIndex + ITEMS_PER_PAGE, data?.length || 0);
    return (_jsxs(Div, { className: s.allApps, tgStyles: wrapperTgStyles, children: [_jsxs("div", { className: s.allAppsTop, children: [_jsx(Title, { variant: "h4", className: s.allAppsTitle, tgStyles: textTgStyles, children: "All Apps" }), _jsxs("div", { className: s.pagination, children: [_jsx("button", { onClick: () => setCurrentPage(prevPage => Math.max(prevPage - 1, 1)), disabled: currentPage === 1, children: _jsx(IconSelector, { id: "chevron-left", size: "20px", color: currentPage === 1 ? '#666' : '#3E88F7' }) }), _jsx("button", { onClick: () => setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages)), disabled: currentPage === totalPages, children: _jsx(IconSelector, { id: "chevron-right", size: "20px", color: currentPage === totalPages ? '#666' : '#3E88F7' }) })] })] }), _jsx("div", { className: s.allAppsItems, children: _jsx("div", { className: s.allAppsItems, children: isLoading ? Array(4).fill(null).map(_ => _jsx(CardSkeleton, { isTg: isTg }, v1())) : data?.slice(startIndex, endIndex).map((el, index) => (_jsxs(Fragment, { children: [_jsx(Card, { ...el }, v1()), index !== endIndex - 1 && _jsx(Div, { className: s.divider, tgStyles: dividerTgStyles })] }, v1()))) }) })] }));
};
