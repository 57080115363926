// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PKV0IALZKE7DPEaGKg0z{display:flex;align-items:center;gap:15px;cursor:pointer;padding-bottom:5px;transition:all .3s ease 0s}.qrBzwrtDENqSLjB1i1gC{object-fit:cover;border-radius:15px;max-width:60px;max-height:60px}.amfPLgXOANnfxyusYfzz{font-size:17px}.r0qCpUMEzF05kTVgtBxL{font-size:13.5px;font-weight:400}.LblxvGzBHYfjqwtuPQWr{width:100%;display:flex;align-items:center;justify-content:space-between;gap:10px}.PKV0IALZKE7DPEaGKg0z:hover>.LblxvGzBHYfjqwtuPQWr>div>svg{transform:scale(1.1)}`, "",{"version":3,"sources":["webpack://./src/components/card/card.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,QAAA,CACA,cAAA,CACA,kBAAA,CACA,0BAAA,CACA,sBACE,gBAAA,CACA,kBAAA,CACA,cAAA,CACA,eAAA,CAEF,sBACE,cAAA,CAEF,sBACE,gBAAA,CACA,eAAA,CAEF,sBACE,UAAA,CACA,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,QAAA,CAGA,0DACE,oBAAA","sourcesContent":[".card {\n  display: flex;\n  align-items: center;\n  gap: 15px;\n  cursor: pointer;\n  padding-bottom: 5px;\n  transition: all 0.3s ease 0s;\n  &Img {\n    object-fit: cover;\n    border-radius: 15px;\n    max-width: 60px;\n    max-height: 60px;\n  }\n  &Title {\n    font-size: 17px;\n  }\n  &Description {\n    font-size: 13.5px;\n    font-weight: 400;\n  }\n  &Body {\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    gap: 10px;\n  }\n  &:hover {\n    > .cardBody > div > svg {\n      transform: scale(1.1);\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `PKV0IALZKE7DPEaGKg0z`,
	"cardImg": `qrBzwrtDENqSLjB1i1gC`,
	"cardTitle": `amfPLgXOANnfxyusYfzz`,
	"cardDescription": `r0qCpUMEzF05kTVgtBxL`,
	"cardBody": `LblxvGzBHYfjqwtuPQWr`
};
export default ___CSS_LOADER_EXPORT___;
