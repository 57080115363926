import axios from 'axios';
export class DataApi {
    constructor() {
        this._url = 'https://raw.githubusercontent.com/delab-team/apps-data/main/data.json';
    }
    async getItems() {
        const res = await axios.get(`${this._url}`);
        return res.data.data;
    }
}
