import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useEffect, useState } from 'react';
import { v1 } from 'uuid';
import { PageWrapper, HeaderPanel, Text, IconSelector, Input, Div } from '@delab-team/de-ui';
import { Link } from 'react-router-dom';
import { ResultCard } from '../components/result-card';
import { ROUTES } from '../utils/router';
import { DataApi } from '../logic/data-api';
import s from './layout.module.scss';
const wrapperTgStyles = { headerStyles: { background: 'var(--tg-theme-bg-color)', borderBottom: '1px solid var(--tg-theme-secondary-bg-color)' } };
const headerStyles = { header: { background: 'var(--tg-theme-bg-color)' } };
const inputTgStyles = { input: { background: 'var(--tg-theme-secondary-bg-color)', color: 'var(--tg-theme-text-color)' } };
const textTgStyles = { color: 'var(--tg-theme-text-color)' };
export const Layout = ({ children }) => {
    const [value, setValue] = useState('');
    const [data, setData] = useState(undefined);
    const [searchData, setSearchData] = useState(undefined);
    const API = new DataApi();
    useEffect(() => {
        try {
            API.getItems()
                .then((res) => {
                setData(res);
            })
                .catch((error) => {
                console.error('Error when including data:', error);
            });
        }
        catch (error) {
            console.error('API.getItems():', error);
        }
    }, []);
    useEffect(() => {
        if (data) {
            const filteredData = data.filter(item => item.title.toLowerCase().includes(value.toLowerCase()));
            setSearchData(filteredData);
        }
    }, [data, value]);
    return (_jsx(PageWrapper, { className: s.wrapper, headerClassName: s.wrapperHeader, containerWidth: "500px", tgStyles: wrapperTgStyles, header: _jsx(HeaderPanel, { title: "", containerWidth: "460px", variant: "black", className: s.header, tgStyles: headerStyles, actionLeft: _jsx(Link, { to: ROUTES.HOME, className: s.logo, children: _jsx(Text, { className: s.logo, fontWeight: "bold", tgStyles: { color: 'var(--tg-theme-text-color)' }, children: "DeApps" }) }), actionRight: _jsxs("div", { className: s.searchInner, children: [_jsx(IconSelector, { id: "search", color: "#98989E", className: s.searchIcon, size: "20", tgStyles: { stroke: 'var(--tg-theme-link-color)' } }), _jsx(Input, { placeholder: "Find your app", value: value, onChange: (e) => setValue(e.target.value), variant: "black", className: `${s.search} ${s.searchHome}`, tgStyles: inputTgStyles }), value.length >= 1 && (_jsx("button", { className: s.searchClear, onClick: () => setValue(''), children: _jsx(IconSelector, { id: "x", size: '20', color: "#fff", tgStyles: { stroke: 'var(--tg-theme-link-color)' } }) })), value.length >= 1 && (_jsx(Div, { className: s.searchResult, tgStyles: { background: 'var(--tg-theme-secondary-bg-color)' }, children: searchData?.length !== 0 ? searchData?.map(el => _jsx(ResultCard, { ...el }, v1())) : _jsx(Text, { className: s.notFound, tgStyles: textTgStyles, children: "Not Found" }) }))] }) }), footer: _jsx(_Fragment, {}), pageTitle: "DeApps", content: _jsx("div", { className: s.wrapperContent, children: children }) }));
};
