// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._2dzWWDKIfD7AFnBiuuED{display:flex;align-items:start;gap:15px;cursor:pointer;transition:all .3s ease 0s}._2dzWWDKIfD7AFnBiuuED:not(:last-child){margin-bottom:5px}.vUItDlCZPesH3MWWpp0w{object-fit:cover;border-radius:15px;margin-bottom:5px}.PWziyG8fzW90qa_zbrWf{font-size:13px}.KeM0oo3K9Vj2TZ8LNKex{font-size:12px;font-weight:400}._2dzWWDKIfD7AFnBiuuED:hover>._l8rC7En0fLD9uNsX1u0>div>svg{transform:scale(1.1)}`, "",{"version":3,"sources":["webpack://./src/components/result-card/result-card.module.scss"],"names":[],"mappings":"AAAA,uBACE,YAAA,CACA,iBAAA,CACA,QAAA,CACA,cAAA,CACA,0BAAA,CACA,wCACE,iBAAA,CAEF,sBACE,gBAAA,CACA,kBAAA,CACA,iBAAA,CAEF,sBACE,cAAA,CAEF,sBACE,cAAA,CACA,eAAA,CAGA,2DACE,oBAAA","sourcesContent":[".resultCard {\n  display: flex;\n  align-items: start;\n  gap: 15px;\n  cursor: pointer;\n  transition: all 0.3s ease 0s;\n  &:not(:last-child) {\n    margin-bottom: 5px;\n  }\n  &Img {\n    object-fit: cover;\n    border-radius: 15px;\n    margin-bottom: 5px;\n  }\n  &Title {\n    font-size: 13px;\n  }\n  &Description {\n    font-size: 12px;\n    font-weight: 400;\n  }\n  &:hover {\n    > .cardBody > div > svg {\n      transform: scale(1.1);\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resultCard": `_2dzWWDKIfD7AFnBiuuED`,
	"resultCardImg": `vUItDlCZPesH3MWWpp0w`,
	"resultCardTitle": `PWziyG8fzW90qa_zbrWf`,
	"resultCardDescription": `KeM0oo3K9Vj2TZ8LNKex`,
	"cardBody": `_l8rC7En0fLD9uNsX1u0`
};
export default ___CSS_LOADER_EXPORT___;
