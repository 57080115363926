import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconSelector, Text } from '@delab-team/de-ui';
import s from './card.module.scss';
const textTgStyles = { color: 'var(--tg-theme-text-color)' };
const iconsTgStyles = { stroke: 'var(--tg-theme-link-color)' };
export const Card = ({ preview_img, app_link, title, description }) => {
    const TgObj = window.Telegram.WebApp;
    const handleClick = () => {
        if (app_link.startsWith('https://t.me')) {
            TgObj.openTelegramLink(app_link);
        }
        else {
            window.open(app_link, '_blank');
        }
    };
    return (_jsxs("div", { className: s.card, onClick: handleClick, children: [_jsx("img", { src: preview_img, className: s.cardImg, alt: title, width: "60", height: "60" }), _jsxs("div", { className: s.cardBody, children: [_jsxs("div", { children: [_jsx(Text, { className: s.cardTitle, tgStyles: textTgStyles, children: title }), _jsx(Text, { className: s.cardDescription, tgStyles: textTgStyles, children: description })] }), _jsx(IconSelector, { id: "chevron-right", size: "16px", color: "#fff", tgStyles: iconsTgStyles })] })] }));
};
