import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Text } from '@delab-team/de-ui';
import s from './result-card.module.scss';
const textTgStyles = { color: 'var(--tg-theme-text-color)' };
export const ResultCard = ({ app_link, description, preview_img, title }) => {
    const TgObj = window.Telegram.WebApp;
    const handleClick = () => {
        if (app_link.startsWith('https://t.me')) {
            TgObj.openTelegramLink(app_link);
        }
        else {
            window.open(app_link, '_blank');
        }
    };
    return (_jsxs("div", { className: s.resultCard, onClick: handleClick, children: [_jsx("img", { src: preview_img, className: s.resultCardImg, width: "30", height: "30", alt: "preview img" }), _jsxs("div", { className: s.resultCardBody, children: [_jsx(Text, { className: s.resultCardTitle, tgStyles: textTgStyles, children: title }), _jsx(Text, { className: s.resultCardDescription, tgStyles: textTgStyles, children: description })] })] }));
};
